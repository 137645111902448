class GTag {
    constructor() {
        this.id = process.env.GTAG_ID || null
    }

    load() {
        return new Promise((resolve, reject) => {
            if (! this.id) {
                reject();
            }

            const script = document.createElement("script");
            const src = `https://www.googletagmanager.com/gtag/js?id=${this.id}`
            function gtag() {
                dataLayer.push(arguments);
            }
            (script.src = src),
                (script.async = !0),
                document.getElementsByTagName('head')[0].appendChild(script),
                (window.dataLayer = window.dataLayer || []),
                gtag("js", new Date()),
                gtag("config", this.id);

            resolve(script);
        });
    }
}

document.addEventListener('DOMContentLoaded', (event) => {
    setTimeout(() => {
        new GTag().load().then(() => {
            console.info('Google Analytics start.')
        })
    }, 2000)
});
